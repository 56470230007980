import React, { useState, createContext } from 'react'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { useEffect } from 'react'

// Create Context Object
export const ContractContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const ContractContextProvider = (props) => {
  const { ownerAddress, jsonAbi } = props

  const setContractSigner = (signer) => {
    contract.connect(signer)
  }

  const abiInterface = new utils.Interface(jsonAbi)
  const contract = new Contract(ownerAddress, abiInterface)

  contract?.events
    ?.allEvents()
    .on('data', (event) => {
      console.log(event)
    })
    .on('error', console.error);

  return (
    <ContractContext.Provider
      value={{ ownerAddress, contract, abiInterface, setContractSigner}}
    >
      {props.children}
    </ContractContext.Provider>
  )
}
